// export const baseURL = "//urologiq.biocliq.ai/aarthi_server/infer";
// export const baseURLAPI = "//urologic.biocliq.ai/aarthi_server/api";
export const inferURLs = {
  //    "aarthi": "//urologic.biocliq.ai/aarthi_server/infer",
  // "aarthi": "//inference2.urologiq.ai/aarthi_cropped_server/infer",
  // "prima": "//inference1.urologiq.ai/prima_server/infer",
  // "gujimg": "//inference1.urologiq.ai/gujarat_server/infer",
  // "test-demo": "//inference1.urologiq.ai/test_demo_server/infer",
  // "medimax": "//inference4.urologiq.ai/urologiq_test/medimax_server/infer",
  // "jiva-cropped": "//inference1.urologiq.ai/jiva_kub_cropped/infer",
  //    "kauvery": "//inference1.urologiq.ai/kauvery/infer",
  //    "gic_cropped": "//inference4.urologiq.ai/gic_cropped/infer",
  sahyadri: "//inference4.urologiq.ai/urologiq_test/sahyadri/infer",
  // "ths_hosp": "//inference4.urologiq.ai/urologiq_test/ths/infer",
  magnet_diag: "//inference4.urologiq.ai/urologiq_test/magnet_diag/infer",
  // "5c": "//inference4.urologiq.ai/urologiq_test/5c/infer",
  aarthi: "//inference4.urologiq.ai/urologiq_test/aarthi/infer",
  //agnapp: "//inference4.urologiq.ai/urologiq_test/agnapp/infer",
  alpha: "//inference4.urologiq.ai/urologiq_test/alpha/infer",
  ainu: "//inference4.urologiq.ai/urologiq_test/ainu/infer",
  ambikey: "//inference4.urologiq.ai/urologiq_test/ambikey/infer",
  apollo: "//inference4.urologiq.ai/urologiq_test/apollo/infer",
  advimaging: "//inference4.urologiq.ai/urologiq_test/advimaging/infer",
  //blde: "//inference4.urologiq.ai/urologiq_test/blde/infer",
  kidney_hosp: "//inference4.urologiq.ai/urologiq_test/kidney_hosp/infer",
  cect: "//inference4.urologiq.ai/urologiq_test/cect/infer",
  trial: "//inference4.urologiq.ai/urologiq_test/trial/infer",
  //aster: "//inference4.urologiq.ai/urologiq_test/aster/infer",
  ayucare: "//inference4.urologiq.ai/urologiq_test/ayucare/infer",
  gic_mnagar: "//inference4.urologiq.ai/urologiq_test/gic_mnagar/infer",
  apollo_hyd: "//inference4.urologiq.ai/urologiq_test/apollo_hyd/infer",
  carpl: "//inference4.urologiq.ai/urologiq_test/carpl/infer",
  // cropped_beta: "//inference1.urologiq.ai/cropped_beta/infer",
  megavision: "//inference4.urologiq.ai/urologiq_test/megavision/infer",
  //deeptek: "//inference4.urologiq.ai/urologiq_test/deeptek/infer",
  gic_sadbhav: "//inference4.urologiq.ai/urologiq_test/gic_sadbhav/infer",
  elite: "//inference4.urologiq.ai/urologiq_test/elite/infer",
  //genx: "//inference4.urologiq.ai/urologiq_test/genx/infer",
  gic: "//inference4.urologiq.ai/urologiq_test/gic/infer",
  gicprime: "//inference4.urologiq.ai/urologiq_test/gicprime/infer",
  godavari: "//inference4.urologiq.ai/urologiq_test/godavari/infer",
  //healthsquare:
  //  "//inference4.urologiq.ai/urologiq_test/healthsquare/infer",
  //imagebytes: "//inference4.urologiq.ai/urologiq_test/imagebytes/infer",
  //ishan: "//inference4.urologiq.ai/urologiq_test/ishan/infer",
  sathyasai: "//inference4.urologiq.ai/urologiq_test/sathyasai/infer",
  kauvery: "//inference4.urologiq.ai/urologiq_test/kauvery/infer",
  kims: "//inference4.urologiq.ai/urologiq_test/kims/infer",
  kims_kp: "//inference4.urologiq.ai/urologiq_test/kims_kp/infer",
  radcare: "//inference4.urologiq.ai/urologiq_test/radcare/infer",
  lifeline: "//inference4.urologiq.ai/urologiq_test/lifeline/infer",
  // medimax: "//inference4.urologiq.ai/urologiq_test/medimax/infer",
  mediscan: "//inference4.urologiq.ai/urologiq_test/mediscan/infer",
  //newmedd: "//inference4.urologiq.ai/urologiq_test/newmedd/infer",
  // medsynaptic: "//inference4.urologiq.ai/urologiq_test/medsynaptic/infer",
  meenakshi: "//inference4.urologiq.ai/urologiq_test/meenakshi/infer",
  meenakshi_m: "//inference4.urologiq.ai/urologiq_test/meenakshi_m/infer",
  meenakshi_b: "//inference4.urologiq.ai/urologiq_test/meenakshi_b/infer",
  metroimaging: "//inference4.urologiq.ai/urologiq_test/metroimaging/infer",
  neoshealthtech:
    "//inference4.urologiq.ai/urologiq_test/neoshealthtech/infer",
  nidan: "//inference4.urologiq.ai/urologiq_test/nidan/infer",
  nirman: "//inference4.urologiq.ai/urologiq_test/nirman/infer",
  niramay: "//inference4.urologiq.ai/urologiq_test/niramay/infer",
  ozone: "//inference4.urologiq.ai/urologiq_test/ozone/infer",
  akola_diag: "//inference4.urologiq.ai/urologiq_test/akola_diag/infer",
  //prima: "//inference4.urologiq.ai/urologiq_test/prima/infer",
  raviscans: "//inference4.urologiq.ai/urologiq_test/raviscans/infer",
  isha: "//inference4.urologiq.ai/urologiq_test/isha/infer",
  bodyline: "//inference4.urologiq.ai/urologiq_test/bodyline/infer",
  //rubycare: "//inference4.urologiq.ai/urologiq_test/rubycare/infer",
  bt_savani: "//inference4.urologiq.ai/urologiq_test/bt_savani/infer",
  manavseva: "//inference4.urologiq.ai/urologiq_test/manavseva/infer",
  kauvery_vpl: "//inference4.urologiq.ai/urologiq_test/kauvery_vpl/infer",
  siddhivinayak:
    "//inference4.urologiq.ai/urologiq_test/siddhivinayak/infer",
  sukhmani: "//inference4.urologiq.ai/urologiq_test/sukhmani/infer",
  //abhiscans: "//inference4.urologiq.ai/urologiq_test/abhiscans/infer",
  test2: "//inference4.urologiq.ai/urologiq_test/test2/infer",
  tmu: "//inference4.urologiq.ai/urologiq_test/tmu/infer",
  thsdiag: "//inference4.urologiq.ai/urologiq_test/thsdiag/infer",
  vijaya: "//inference4.urologiq.ai/urologiq_test/vijaya/infer",
  sapthagiri: "//inference4.urologiq.ai/urologiq_test/sapthagiri/infer",
};
export const apiURLs = {
  //    "aarthi": "//urologic.biocliq.ai/aarthi_server/api",
  // "aarthi": "//inference2.urologiq.ai/aarthi_cropped_server/api",
  // "prima": "//inference1.urologiq.ai/prima_server/api",
  // "gujimg": "//inference1.urologiq.ai/gujarat_server/api",
  // "test-demo": "//inference1.urologiq.ai/test_demo_server/api",
  // "medimax": "//inference4.urologiq.ai/urologiq_test/medimax_server/api",
  // "jiva-cropped": "//inference1.urologiq.ai/jiva_kub_cropped/api",
  //   "kauvery": "//inference1.urologiq.ai/kauvery/api",
  //   "gic_cropped": "//inference4.urologiq.ai/gic_cropped/api",
  sahyadri: "//inference4.urologiq.ai/urologiq_test/sahyadri/api",
  // "ths_hosp": "//inference4.urologiq.ai/urologiq_test/ths/api",
  magnet_diag : "//inference4.urologiq.ai/urologiq_test/magnet_diag/api",
  // "5c": "//inference4.urologiq.ai/urologiq_test/5c/api",
  aarthi: "//inference4.urologiq.ai/urologiq_test/aarthi/api",
  trial: "//inference4.urologiq.ai/urologiq_test/trial/api",
  //aster: "//inference4.urologiq.ai/urologiq_test/aster/api",
  ayucare: "//inference4.urologiq.ai/urologiq_test/ayucare/api",
  gic_mnagar: "//inference4.urologiq.ai/urologiq_test/gic_mnagar/api",
  apollo_hyd: "//inference4.urologiq.ai/urologiq_test/apollo_hyd/api",
  carpl: "//inference4.urologiq.ai/urologiq_test/carpl/api",
  // cropped_beta: "//inference1.urologiq.ai/cropped_beta/api",
  megavision: "//inference4.urologiq.ai/urologiq_test/megavision/api",
  cect: "//inference4.urologiq.ai/urologiq_test/cect/api",
  //deeptek: "//inference4.urologiq.ai/urologiq_test/deeptek/api",
  gic_sadbhav: "//inference4.urologiq.ai/urologiq_test/gic_sadbhav/api",
  elite: "//inference4.urologiq.ai/urologiq_test/elite/api",
  //genx: "//inference4.urologiq.ai/urologiq_test/genx/api",
  gic: "//inference4.urologiq.ai/urologiq_test/gic/api",
  godavari: "//inference4.urologiq.ai/urologiq_test/godavari/api",
  //healthsquare: "//inference4.urologiq.ai/urologiq_test/healthsquare/api",
  //imagebytes: "//inference4.urologiq.ai/urologiq_test/imagebytes/api",
  //ishan: "//inference4.urologiq.ai/urologiq_test/ishan/api",
  kauvery: "//inference4.urologiq.ai/urologiq_test/kauvery/api",
  radcare: "//inference4.urologiq.ai/urologiq_test/radcare/api",
  lifeline: "//inference4.urologiq.ai/urologiq_test/lifeline/api",
  // medimax: "//inference4.urologiq.ai/urologiq_test/medimax/api",
  mediscan: "//inference4.urologiq.ai/urologiq_test/mediscan/api",
  //newmedd: "//inference4.urologiq.ai/urologiq_test/newmedd/api",
  // medsynaptic: "//inference4.urologiq.ai/urologiq_test/medsynaptic/api",
  metroimaging: "//inference4.urologiq.ai/urologiq_test/metroimaging/api",
  neoshealthtech:
    "//inference4.urologiq.ai/urologiq_test/neoshealthtech/api",
  nidan: "//inference4.urologiq.ai/urologiq_test/nidan/api",
  nirman: "//inference4.urologiq.ai/urologiq_test/nirman/api",
  niramay: "//inference4.urologiq.ai/urologiq_test/niramay/api",
  ozone: "//inference4.urologiq.ai/urologiq_test/ozone/api",
  //prima: "//inference4.urologiq.ai/urologiq_test/prima/api",
  isha: "//inference4.urologiq.ai/urologiq_test/isha/api",
  bt_savani: "//inference4.urologiq.ai/urologiq_test/bt_savani/api",
  manavseva: "//inference4.urologiq.ai/urologiq_test/manavseva/api",
  sukhmani: "//inference4.urologiq.ai/urologiq_test/sukhmani/api",
  //abhiscans: "//inference4.urologiq.ai/urologiq_test/abhiscans/api",
  test2: "//inference4.urologiq.ai/urologiq_test/test2/api",
  thsdiag: "//inference4.urologiq.ai/urologiq_test/thsdiag/api",
  meenakshi: "//inference4.urologiq.ai/urologiq_test/meenakshi/api",
  kidney_hosp: "//inference4.urologiq.ai/urologiq_test/kidney_hosp/api",
  raviscans: "//inference4.urologiq.ai/urologiq_test/raviscans/api",
  akola_diag: "//inference4.urologiq.ai/urologiq_test/akola_diag/api",
  vijaya: "//inference4.urologiq.ai/urologiq_test/vijaya/api",
  //rubycare: "//inference4.urologiq.ai/urologiq_test/rubycare/api",
  advimaging: "//inference4.urologiq.ai/urologiq_test/advimaging/api",
  ainu: "//inference4.urologiq.ai/urologiq_test/ainu/api",
  ambikey: "//inference4.urologiq.ai/urologiq_test/ambikey/api",
  siddhivinayak:
    "//inference4.urologiq.ai/urologiq_test/siddhivinayak/api",
  meenakshi_m: "//inference4.urologiq.ai/urologiq_test/meenakshi_m/api",
  bodyline: "//inference4.urologiq.ai/urologiq_test/bodyline/api",
  gicprime: "//inference4.urologiq.ai/urologiq_test/gicprime/api",
  alpha: "//inference4.urologiq.ai/urologiq_test/alpha/api",
  apollo: "//inference4.urologiq.ai/urologiq_test/apollo/api",
  meenakshi_b: "//inference4.urologiq.ai/urologiq_test/meenakshi_b/api",
  tmu: "//inference4.urologiq.ai/urologiq_test/tmu/api",
  kims: "//inference4.urologiq.ai/urologiq_test/kims/api",
  kims_kp: "//inference4.urologiq.ai/urologiq_test/kims_kp/api",
  //agnapp: "//inference4.urologiq.ai/urologiq_test/agnapp/api",
  sathyasai: "//inference4.urologiq.ai/urologiq_test/sathyasai/api",
  kauvery_vpl: "//inference4.urologiq.ai/urologiq_test/kauvery_vpl/api",
  //blde: "//inference4.urologiq.ai/urologiq_test/blde/api",
  sapthagiri: "//inference4.urologiq.ai/urologiq_test/sapthagiri/api",
};
export const ohifURLs = {
  //   "aarthi": "//urologic.biocliq.ai/aarthi/ohif_viewer/viewer",
  // "aarthi": "//inference2.urologiq.ai/aarthi_cropped/ohif_viewer/viewer",
  jivaa: "//inference1.urologiq.ai/ohif_viewer/viewer",
  // "prima": "//inference1.urologiq.ai/prima/ohif_viewer/viewer",
  // "gujimg": "//inference1.urologiq.ai/gujarat/ohif_viewer/viewer",
  // "test-demo": "//inference1.urologiq.ai/test_demo/ohif_viewer/viewer",
  cropped_beta:
    "//inference1.urologiq.ai/cropped_beta/ohif_viewer/viewer",
  megavision: 
    "//inference4.urologiq.ai/urologiq_test/megavision/ohif_viewer/viewer",
  // "medimax": "//inference4.urologiq.ai/medimax/urologiq_test/ohif_viewer/viewer",
  // "jiva-cropped": "//inference1.urologiq.ai/jiva_kub_cropped/ohif_viewer/viewer",
  //   "kauvery": "//inference1.urologiq.ai/kauvery/ohif_viewer/viewer",
  kauvery:
    "//inference4.urologiq.ai/urologiq_test/kauvery/ohif_viewer/viewer",
  radcare:
    "//inference4.urologiq.ai/urologiq_test/radcare/ohif_viewer/viewer",
  //   "gic_cropped": "//inference4.urologiq.ai/gic_cropped/ohif_viewer/viewer",
  nirman:
    "//inference4.urologiq.ai/urologiq_test/nirman/ohif_viewer/viewer",
  neoshealthtech:
    "//inference4.urologiq.ai/urologiq_test/neoshealthtech/ohif_viewer/viewer",
  test2:
    "//inference4.urologiq.ai/urologiq_test/test2/ohif_viewer/viewer",
  gic: "//inference4.urologiq.ai/urologiq_test/gic/ohif_viewer/viewer",
  godavari:
    "//inference4.urologiq.ai/urologiq_test/godavari/ohif_viewer/viewer",
  //healthsquare:
  //  "//inference4.urologiq.ai/urologiq_test/healthsquare/ohif_viewer/viewer",
  ozone:
    "//inference4.urologiq.ai/urologiq_test/ozone/ohif_viewer/viewer",
  //prima: "//inference4.urologiq.ai/urologiq_test/prima/ohif_viewer/viewer",
  mediscan:
    "//inference4.urologiq.ai/urologiq_test/mediscan/ohif_viewer/viewer",
  //newmedd: "//inference4.urologiq.ai/urologiq_test/newmedd/ohif_viewer/viewer",
  trial:
    "//inference4.urologiq.ai/urologiq_test/trial/ohif_viewer/viewer",
  medimax:
    "//inference4.urologiq.ai/urologiq_test/medimax/ohif_viewer/viewer",
  "5c": "//inference4.urologiq.ai/urologiq_test/5c/ohif_viewer/viewer",
  sahyadri:
    "//inference4.urologiq.ai/urologiq_test/sahyadri/ohif_viewer/viewer",
  // "ths_hosp": "//inference4.urologiq.ai/urologiq_test/ths/ohif_viewer/viewer",
  lifeline:
    "//inference4.urologiq.ai/urologiq_test/lifeline/ohif_viewer/viewer",
  ayucare:
    "//inference4.urologiq.ai/urologiq_test/ayucare/ohif_viewer/viewer",
  //deeptek:
  //  "//inference4.urologiq.ai/urologiq_test/deeptek/ohif_viewer/viewer",
  elite:
    "//inference4.urologiq.ai/urologiq_test/elite/ohif_viewer/viewer",
  aarthi:
    "//inference4.urologiq.ai/urologiq_test/aarthi/ohif_viewer/viewer",
  magnet_diag:
    "//inference4.urologiq.ai/urologiq_test/magnet_diag/ohif_viewer/viewer",
  bt_savani:
    "//inference4.urologiq.ai/urologiq_test/bt_savani/ohif_viewer/viewer",
  gic_mnagar:
    "//inference4.urologiq.ai/urologiq_test/gic_mnagar/ohif_viewer/viewer",
  apollo_hyd:
    "//inference4.urologiq.ai/urologiq_test/apollo_hyd/ohif_viewer/viewer",
  //genx: "//inference4.urologiq.ai/urologiq_test/genx/ohif_viewer/viewer",
  //ishan: "//inference4.urologiq.ai/urologiq_test/ishan/ohif_viewer/viewer",
  //abhiscans: "//inference4.urologiq.ai/urologiq_test/abhiscans/ohif_viewer/viewer",
  manavseva:
    "//inference4.urologiq.ai/urologiq_test/manavseva/ohif_viewer/viewer",
  sukhmani:
    "//inference4.urologiq.ai/urologiq_test/sukhmani/ohif_viewer/viewer",
  //aster: "//inference4.urologiq.ai/urologiq_test/aster/ohif_viewer/viewer",
  ths: "//inference4.urologiq.ai/urologiq_test/thsdiag/ohif_viewer/viewer",
  thsdiag:
    "//inference4.urologiq.ai/urologiq_test/thsdiag/ohif_viewer/viewer",
  // medsynaptic: "//inference4.urologiq.ai/urologiq_test/medsynaptic/ohif_viewer/viewer",
  //imagebytes: "//inference4.urologiq.ai/urologiq_test/imagebytes/ohif_viewer/viewer",
  carpl:
    "//inference4.urologiq.ai/urologiq_test/carpl/ohif_viewer/viewer",
  nidan:
    "//inference4.urologiq.ai/urologiq_test/nidan/ohif_viewer/viewer",
  niramay:
    "//inference4.urologiq.ai/urologiq_test/niramay/ohif_viewer/viewer",
  isha: "//inference4.urologiq.ai/urologiq_test/isha/ohif_viewer/viewer",
  metroimaging:
    "//inference4.urologiq.ai/urologiq_test/metroimaging/ohif_viewer/viewer",
  gic_sadbhav:
    "//inference4.urologiq.ai/urologiq_test/gic_sadbhav/ohif_viewer/viewer",
  meenakshi:
    "//inference4.urologiq.ai/urologiq_test/meenakshi/ohif_viewer/viewer",
  kidney_hosp:
    "//inference4.urologiq.ai/urologiq_test/kidney_hosp/ohif_viewer/viewer",
  raviscans:
    "//inference4.urologiq.ai/urologiq_test/raviscans/ohif_viewer/viewer",
  akola_diag:
    "//inference4.urologiq.ai/urologiq_test/akola_diag/ohif_viewer/viewer",
  vijaya:
    "//inference4.urologiq.ai/urologiq_test/vijaya/ohif_viewer/viewer",
  //rubycare: "//inference4.urologiq.ai/urologiq_test/rubycare/ohif_viewer/viewer",
  advimaging:
    "//inference4.urologiq.ai/urologiq_test/advimaging/ohif_viewer/viewer",
  ainu: "//inference4.urologiq.ai/urologiq_test/ainu/ohif_viewer/viewer",
  ambikey:
    "//inference4.urologiq.ai/urologiq_test/ambikey/ohif_viewer/viewer",
  siddhivinayak:
    "//inference4.urologiq.ai/urologiq_test/siddhivinayak/ohif_viewer/viewer",
  meenakshi_m:
    "//inference4.urologiq.ai/urologiq_test/meenakshi_m/ohif_viewer/viewer",
  bodyline:
    "//inference4.urologiq.ai/urologiq_test/bodyline/ohif_viewer/viewer",
  gicprime:
    "//inference4.urologiq.ai/urologiq_test/gicprime/ohif_viewer/viewer",
  alpha:
    "//inference4.urologiq.ai/urologiq_test/alpha/ohif_viewer/viewer",
  apollo:
    "//inference4.urologiq.ai/urologiq_test/apollo/ohif_viewer/viewer",
  meenakshi_b:
    "//inference4.urologiq.ai/urologiq_test/meenakshi_b/ohif_viewer/viewer",
  tmu: "//inference4.urologiq.ai/urologiq_test/tmu/ohif_viewer/viewer",
  kims: 
    "//inference4.urologiq.ai/urologiq_test/kims/ohif_viewer/viewer",
  kims_kp: 
    "//inference4.urologiq.ai/urologiq_test/kims_kp/ohif_viewer/viewer",
  //agnapp: "//inference4.urologiq.ai/urologiq_test/agnapp/ohif_viewer/viewer",
  sathyasai:
    "//inference4.urologiq.ai/urologiq_test/sathyasai/ohif_viewer/viewer",
  cect: "//inference4.urologiq.ai/urologiq_test/cect/ohif_viewer/viewer",
  kauvery_vpl:
    "//inference4.urologiq.ai/urologiq_test/kauvery_vpl/ohif_viewer/viewer",
  //blde: "//inference4.urologiq.ai/urologiq_test/blde/ohif_viewer/viewer",
  sapthagiri:
    "//inference4.urologiq.ai/urologiq_test/sapthagiri/ohif_viewer/viewer",
};

export const analyticsAPIs = {
  inference4:
    "//inference4.urologiq.ai/urologiq_test/aarthi/api/analytics",
};

export const allColors = [
  // [0, 0, 0],
  [128, 174, 128],
  [241, 214, 145],
  [177, 122, 101],
  [111, 184, 210],
  [216, 101, 79],
  [221, 130, 101],
  [144, 238, 144],
  [220, 245, 20],
  [192, 104, 88],
  [78, 63, 0],
  [255, 250, 220],
  [230, 220, 70],
  [200, 200, 235],
  [250, 250, 210],
  [244, 214, 49],
  [0, 151, 206],
];

// red
export const stoneColors = [
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
  [128, 0, 0],
  [192, 0, 0],
  [255, 0, 0],
];

